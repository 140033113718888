// Required
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400&display=swap');
@import url('https://unpkg.com/vue-tel-input@5.12.0/dist/vue-tel-input.css');
.review-link-widget{
  font-family: 'Roboto', sans-serif;
  font-size: 1rem;
  margin: 30px 0;
  padding: 25px 20px 30px 20px;
  .vue-tel-input {
    border-radius: 8px;
    background: #fff;
    border-color: #ccc;
    input[type="tel"]{
      border:0;
    }
    .vti__dropdown:hover{
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }
  }
  h3{
    font-size: 2em;
    font-weight: 400;
    margin-bottom: .5em;
    margin-top: 0;
  }
  .place-search-row{
    margin-bottom: 10px;
  }
  .just-center{
    justify-content: center;
  }
  .text-center{
    text-align: center;
  }
  .float-left{
    float: left;
  }
  .rl-mt-4{
    margin-top: 1.5rem;
  }
  .center-content{
    align-items: center;
    justify-content: center;
  }
  .row{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;
    [class*="col"]{
      display: flex;
      flex-direction: column;
      flex: 1;
      padding: 5px 15px;
      &.double-column {
        flex:2
      }
    }
  }
  > .row{
    margin-left: -25px;
    margin-right: -25px;
    > [class*="col"]{
      padding-right: 25px;
      padding-left: 25px;
    }
  }
  input, button, .btn{
    font-size: 1.25rem;
    width: 100%;
    border-radius: 7px;
    line-height: 30px;
    border: 0;
    padding: 8px;
    box-sizing: border-box;
    &:focus-visible{
      outline: none;
    }
    &:disabled{
      background: hsla(0,0%,81.6%,.5);
      text-align: left;
    }
  }
  #placesearch{
    text-align: left;
  }
  button, .btn{
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    &:hover{
      opacity: 0.9;
    }
  }
  input.has-error{
    border: 1px solid red;
  }
  input[type="text"], input[type="tel"]{
    border: 1px solid #ccc;
  }
  span.error{display: block; margin-top: 5px;}
  .link-display{
    padding: 10px;
    background: #eff3f7;
    border-radius: 10px;
    .intro-image{
      width: 30px;
      display: inline-block;
    }
    .intro-text{
      width: calc(100% - 30px);
      display: inline-block;
      padding-left: 20px;
      vertical-align: top;
      box-sizing: border-box;
      strong{
        font-weight: bold;
        display: block;
        font-size: 16px;
        margin: 0;
      }
      em{
        margin: 0;
        font-size: 13px;
      }
    }
    .copy-link{
      width: 100%;
      margin-top: 15px;
      input{
        width: calc(100% - 65px);
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
      }
      button{
        width: 65px;
        line-height: 30px;
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
        vertical-align: top;
        min-height: 48px;
        svg{
          height: 25px;
          vertical-align: text-bottom;
        }
      }
    }
    .input-group-append button{
      background: #537bba;
      color: white;
    }
  }
  .qr-row{
    > hr{
      width: calc(100% - 50px);
      box-sizing: border-box;
      border-color: #545454;
    }
  }
  .spinner{
    -webkit-animation-name: spin;
    animation-name: spin;
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
    -webkit-animation-direction: normal;
    animation-direction: normal;
    height: 30px;
    path { // or rect, circle, etc
      shape-rendering: optimizeSpeed;
    }
  }
  @keyframes spin {
    from {
      transform:rotate(0deg);
    }
    to {
      transform:rotate(360deg);
    }
  }
}
@media screen and (max-width: 767px){
  iframe.map-frame{
    max-width: 100%;
    height: auto;
    margin-top: 15px;
    min-height: 265px;
  }
  .double-column{
    flex:1 !important;
  }
  .col-lg-6{
    width: 100% !important;
    display: block !important;
  }
  .reply-engine-widget .row [class*="col"]{
    width: 100%;
    display: block;
    flex: none;
    box-sizing: border-box;
  }
  .view-link, .view-map{
    width: 100%;
    display: block;
  }
}
